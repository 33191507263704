@tailwind base;
@tailwind components;
@tailwind utilities;


video::-webkit-media-controls {
    display: none !important;
  }
  
  video::-webkit-media-controls-enclosure {
    display: none !important;
  }
  
  video::-webkit-media-controls-panel {
    display: none !important;
  }
  
  